<template>
  <div class="card">
    <AppText
      size="12px"
      type="uppercase"
      class="font-medium"
      variant="div"
    >
      {{ title }}
    </AppText>
    <div class="number">
      <FSkeleton variant="text" :is-loading="isLoading" height="28px" transition-name="fade-absolute-1">
        <div class="position-relative">
          <CurrencyFormatter
            v-if="hasData"
            size="23px"
            class="font-medium"
            :value="cardData.amount"
            is-account-currency
            is-raw
            is-stat
          />
        </div>
      </FSkeleton>
    </div>
    <div class="payments">
      <FSkeleton variant="text" :is-loading="isLoading" height="17px" transition-name="fade-absolute-1">
        <div class="position-relative">
          <AppText v-if="hasData" color="var(--color-black-04)" style="height: 17px;">
            {{ cardData.confirmed ?? '-' }}  {{ t('common.payment', cardData.confirmed) }}
          </AppText>
        </div>
      </FSkeleton>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

export default {
  name: 'IncomeCard',
  components: {
    CurrencyFormatter,
  },
  props: {
    cardData: {
      type: Object,
      default() {
        return {
          value: '',
          payments: '',
        };
      },
    },
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const hasData = computed(() => props.cardData?.amount >= 0);

    return {
      hasData,
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  min-width: 128px;
  padding: 25px 0;
  height: 128px;

  .number, .payments {
    margin-top: 10px;
  }
  .payments {
    height: 17px;
  }
}
</style>
