<template>
  <div class="data-cards">
    <DataCard
      :value="clientsTotal"
      :title="t('common.clients')"
      :is-loading="isLoading"
    />

    <DotSeparator color="#ccc" size="10px" />

    <DataCardExtended
      :value="subscriptionsActive"
      :total="subscriptionsTotal"
      :title="t('common.subscription', 2)"
      :is-loading="isLoading"
    />

    <DotSeparator color="#ccc" size="10px" />

    <DataCardExtended
      :value="paymentsActive"
      :total="paymentsTotal"
      :title="t('common.payments')"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';

import DataCard from './DataCard.vue';
import DataCardExtended from './DataCardExtended.vue';

export default defineComponent({
  name: 'DataCards',
  components: {
    DataCard,
    DataCardExtended,
    DotSeparator,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const clientsTotal = computed(() => props.data.clients?.total);

    const subscriptionsActive = computed(() => props.data.subscriptions?.active);
    const subscriptionsTotal = computed(() => props.data.subscriptions?.total);

    const paymentsActive = computed(() => props.data.payments?.active);
    const paymentsTotal = computed(() => props.data.payments?.total);

    return {
      clientsTotal,
      subscriptionsActive,
      subscriptionsTotal,
      paymentsActive,
      paymentsTotal,
    };
  },
});
</script>

<style scoped lang="scss">
.data-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  row-gap: 0;

  :deep(.dot) {
    margin-top: 55px;
  }

  &:hover {
    :deep(.count .tip) {
      visibility: visible;
    }
  }
}
</style>
