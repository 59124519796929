<template>
  <div>
    <StickyNotification v-if="Boolean(sticky._id)" :item="sticky" />
    <TransitionWrapper>
      <div class="merchant-dashboard">
        <ConnectWalletHint v-if="!isWalletConnected" />

        <IncomeCards :payment-stats="data.paymentStats" :is-loading="isLoading" />

        <DataCards :data="data" :is-loading="isLoading" />

        <div class="income-section">
          <MonthlyIncome
            :years-list="data.yearsList"
            :income-data="data.income"
            :is-loading="isLoading"
          />

          <TotalReceived :data="assetsIncome" :is-loading="isLoading" />
        </div>
      </div>
    </TransitionWrapper>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';

import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';
import StickyNotification from '@/components/Modules/StickyNotification.vue';

import { supportedNetworksFamily } from '@/common/data';
import { getSettings } from '@/api/merchant';
import { emitter, useBus } from '@/composables/useBus';
import { useWallet } from '@/composables/useWallet';
import { useStore, useDashboardStore } from '@/store';
import { useUser } from '@/composables/useUser';
import { useStickies } from '@/composables/useStickies';
import { getPublicStickies } from '@/api/stickies';

import { getAssetsIncome, getTotalData, isFreshResponse } from './api';
import ConnectWalletHint from './components/ConnectWalletHint.vue';
import DataCards from './components/DataCards';
import IncomeCards from './components/IncomeCards';
import MonthlyIncome from './components/MonthlyIncome';
import TotalReceived from './components/TotalReceived';

export default {
  name: 'MerchantDashboard',
  components: {
    ConnectWalletHint,
    IncomeCards,
    DataCards,
    MonthlyIncome,
    TransitionWrapper,
    TotalReceived,
    StickyNotification,
  },
  emits: ['title'],
  setup() {
    const dashboardStore = useDashboardStore();
    const {
      data,
      assetsIncome,
    } = storeToRefs(dashboardStore);

    // const data = ref({
    //   yearsList: [],
    //   income: {
    //     [(new Date()).getFullYear()]: [
    //       { month: 'January', data: {} },
    //       { month: 'February', data: {} },
    //       { month: 'March', data: {} },
    //       { month: 'April', data: {} },
    //       { month: 'May', data: {} },
    //       { month: 'June', data: {} },
    //       { month: 'July', data: {} },
    //       { month: 'August', data: {} },
    //       { month: 'September', data: {} },
    //       { month: 'October', data: {} },
    //       { month: 'November', data: {} },
    //       { month: 'December', data: {} },
    //     ],
    //   },
    //   // ...
    // });
    // const assetsIncome = ref(coinsSortWeight);

    const isLoading = ref(false);
    const store = useStore();

    const getData = async (params) => {
      if (!data.value?.yearsList?.length) {
        isLoading.value = true;
      }
      getSettings();

      Promise.all([
        getAssetsIncome(),
        getTotalData({ ...params }),
      ]).then((result) => {
        supportedNetworksFamily.forEach((network) => {
          delete result[0].data[network];
        });
        dashboardStore.updateAssetsIncome(result[0].data);
        dashboardStore.updateTotalData(result[1]);
        // assetsIncome.value = result[0].data;
        // data.value = result[1];
        isLoading.value = false;
      });

      store.getCurrencySign();
      isFreshResponse.value = true;
    };

    getData();

    const { emitHandler } = useBus();
    emitHandler('update-dashboard-page', () => {
      getData({ fromCache: false });
      getPublicStickies();
    });

    const { companyName } = useUser();
    const { isWalletConnected } = useWallet();
    emitter.emit('page-title', companyName.value);

    onBeforeMount(() => {
      getPublicStickies();
    });

    const { store: stickiesStore } = useStickies();
    const sticky = computed(() => stickiesStore.getMerchantsSticky());

    return {
      data,
      assetsIncome,

      isWalletConnected,
      isLoading,
      sticky,
    };
  },
};
</script>

<style lang="scss" scoped>
.merchant-dashboard {
  width: 100%;
  max-width: 1230px;
  padding-bottom: 100px;

  .income-section {
    border-top: 1px solid var(--color-black-01);
    padding: 30px 0 0 40px;
    display: flex;
    gap: 90px;
    row-gap: 40px;
    flex-wrap: wrap;
  }

  :deep(.income-cards) {
    margin-left: 40px;
    border-bottom: 1px solid var(--color-black-01);
  }

  :deep(.data-cards) {
    padding-left: 40px !important;
  }
}
</style>
