<template>
  <FDropdown ref="dropdownElement" placement="bottom-end" :hide-on-click="false">
    <template #title>
      <AppIcon name="options" size="24px" opacity="0.4" />
    </template>

    <template #content>
      <DropdownItem size="small" @click="onClickMonthly">
        <AppIcon v-show="isMontlyLoading" name="spinner" size="16px" opacity="0.4" />
        <AppIcon
          v-show="!isMontlyLoading"
          name="download"
          size="16px"
          opacity="0.4"
          is-img-tag
        />
        <AppText variant="div" pr="4px" ml="12px">
          {{ t('merchantDashboard.statement.monthly') }}
        </AppText>
      </DropdownItem>

      <DropdownItem size="small" @click="onClickRange">
        <AppIcon name="calendar" size="16px" opacity="0.4" is-img-tag />
        <AppText variant="div" pr="4px" ml="12px">
          {{ t('merchantDashboard.statement.custom') }}
        </AppText>
      </DropdownItem>
    </template>
  </FDropdown>

  <ModalToGetMonthlyStatement v-model="isModalOpen" @date-range="onGetIncomeStatement" />
</template>

<script>
import { computed, ref } from 'vue';
import dayjs from 'dayjs';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { getIncomeStatement } from '@/views/merchant/MerchantDashboard/api';
import { useSettings } from '@/composables/useSettings';
import { useUser } from '@/composables/useUser';
import { useNotifications } from '@/composables/useNotifications';
import { i18n } from '@/plugins/localization';

import ModalToGetMonthlyStatement from './ModalToGetMonthlyStatement.vue';

export default {
  name: 'MonthlyStatement',
  components: {
    DropdownItem,
    ModalToGetMonthlyStatement,
  },
  setup() {
    const { t } = i18n.global;
    const { addNotification } = useNotifications();

    const isModalOpen = ref(false);
    const { user } = useUser();
    const { settings } = useSettings();
    const uid = computed(() => user.value?.uid);

    const dropdownElement = ref(null);

    const isMontlyLoading = ref(false);
    const onClickMonthly = async () => {
      isMontlyLoading.value = true;
      await getIncomeStatement({
        name: `${uid.value}-${dayjs().format('MM-YYYY')}`,
      });
      isMontlyLoading.value = false;
      dropdownElement.value.onForceHandleDropdown();
    };

    const onClickRange = () => {
      dropdownElement.value.onForceHandleDropdown();
      isModalOpen.value = true;
    };

    const onGetIncomeStatement = async (dateFrom, dateTo) => {
      addNotification({
        text: t('merchantDashboard.statement.downloadProcessing'),
        config: { duration: 1000 },
      });
      await new Promise((resolve) => { setTimeout(resolve, 1000); });
      getIncomeStatement({
        name: `${uid.value}-${dayjs(dateFrom).format(settings.value?.dateFormat)}-${dayjs(dateTo).format(settings.value?.dateFormat)}`,
        params: {
          dateFrom,
          dateTo,
        },
      });
    };

    return {
      isModalOpen,

      dropdownElement,

      isMontlyLoading,
      onClickMonthly,

      onClickRange,
      onGetIncomeStatement,
    };
  },
};
</script>

<style scoped lang="scss">
:deep(.f-dropdown--title) {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
</style>
