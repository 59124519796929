<template>
  <div class="data-card">
    <AppText
      size="12px"
      type="uppercase"
      class="font-medium"
      variant="div"
    >
      {{ title }}
    </AppText>

    <div class="info">
      <FSkeleton
        variant="text"
        :is-loading="isLoading"
        height="32px"
        style="width: 120px"
        transition-name="fade-absolute-1"
      >
        <div class="d-flex position-relative">
          <div class="count">
            <AppText
              size="26px"
              type="uppercase"
              class="font-medium"
              variant="div"
            >
              {{ value }}
            </AppText>
            <AppText
              v-if="!isLoading"
              size="11px"
              class="tip font-medium"
              opacity="0.4"
              variant="div"
            >
              {{ t('common.active') }}
            </AppText>
          </div>
          <AppText
            size="26px"
            type="uppercase"
            class="font-medium"
            color="var(--color-black-02)"
            variant="div"
            ml="16px"
            mr="16px"
          >
            /
          </AppText>
          <div class="count">
            <AppText
              size="26px"
              type="uppercase"
              class="font-medium"
              color="var(--color-black-02)"
            >
              {{ total }}
            </AppText>
            <AppText
              size="11px"
              class="tip font-medium"
              opacity="0.4"
              variant="div"
            >
              {{ t('common.total') }}
            </AppText>
          </div>
        </div>
      </FSkeleton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataCardExtended',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-card {
  padding: 30px 0 18px;

  .info {
    margin-top: 8px;
    height: 48px;

    .count {
      position: relative;
      min-width: 18px;

      .tip {
        position: absolute;
        bottom: -16px;
        visibility: hidden;
        min-width: 40px;
        height: 10px;
        @include transition-base('visibility');
      }
    }
  }
}
</style>
