<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="680px"
    :has-header-divider="false"
    :has-body-paddings="false"
    :is-description-visible="false"
    @update:model-value="isModalOpen = !isModalOpen"
    @close="onClose"
  >
    <template #title>
      {{ t('merchantDashboard.statement.title') }}
    </template>

    <div class="monthly-statement-wrapper">
      <DateRangePicker ref="rangePicker" v-model:range="range" :is-picker-open="isModalOpen" />
    </div>

    <div class="d-flex" style="padding: 0 40px 28px;">
      <FButton size="small" @click="onClick">
        {{ t('common.download') }}
      </FButton>

      <FButton size="small" type="plain" @click="onClose">
        {{ t('common.cancel') }}
      </FButton>
    </div>
  </SmoothModal>
</template>

<script setup>
import { ref, watch } from 'vue';

import DateRangePicker from '@/components/ElementUI/DateRangeDropdown/DateRangePicker.vue';
import SmoothModal from '@/components/SmoothModal';

const emit = defineEmits(['dateRange']);

const isModalOpen = defineModel({ type: Boolean, default: false });

const range = ref([]);

const onClick = () => {
  emit('dateRange', range.value[0], range.value[1]);
  onClose();
};

const onClose = () => {
  isModalOpen.value = false;
  range.value = [];
};

const rangePicker = ref(null);
watch(isModalOpen, () => {
  if (isModalOpen.value) {
    setTimeout(() => {
      rangePicker.value.initDates();
    }, 0);
  }
});
</script>

<style lang="scss" scoped>
:deep(.el-button+.el-button) {
  margin-left: 20px;
}

.monthly-statement-wrapper {
  padding: 0 30px 0 30px;
  margin-top: -10px
}
</style>
