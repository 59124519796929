<template>
  <router-link to="/wallet" class="wallet-link">
    <AppIcon name="wallet-orange" size="23px" />
    <AppText
      size="14px"
      variant="div"
      ml="16px"
      mr="4px"
    >
      {{ t('merchantDashboard.setupWallet') }}
    </AppText>
    <AppIcon name="arrow-bracket" class="rotate-90 arrow-style" size="18px" />
  </router-link>
</template>

<script>
export default {
  name: 'ConnectWalletHint',
};
</script>

<style scoped lang="scss">
.wallet-link {
  text-decoration: none;
  color: var(--color-black);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 40px;
  background: linear-gradient(90deg, var(--color-FDF7ED) 65.62%, var(--color-FDF7ED00) 100%);
  width: calc(100% + 10px);

  &:hover {
    color: var(--color-primary);

    .arrow-style :deep(path) {
      fill: var(--color-primary);
    }
  }

  @media screen and (min-width: 1230px) {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      height: 100%;
      width: 100px;
      background: linear-gradient(90deg, transparent, white);
    }
  }
}
</style>
