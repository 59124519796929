<template>
  <div class="data-card position-relative">
    <AppText
      size="12px"
      type="uppercase"
      class="font-medium"
      variant="div"
      mb="8px"
    >
      {{ title }}
    </AppText>
    <FSkeleton
      variant="text"
      :is-loading="isLoading"
      height="32px"
      transition-name="fade-absolute-1"
      style="width: 80px"
    >
      <div class="position-relative">
        <AppText
          size="26px"
          type="uppercase"
          class="font-medium"
          variant="div"
        >
          {{ value }}
        </AppText>
      </div>
    </FSkeleton>
  </div>
</template>

<script>
export default {
  name: 'DataCard',
  props: {
    value: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-card {
  padding: 30px 0 18px;
}
</style>
