<template>
  <div class="income-cards">
    <IncomeCard :card-data="paymentStats?.today" :title="t('merchantDashboard.income.today')" :is-loading="isLoading" />
    <IncomeCard :card-data="paymentStats?.week" :title="t('merchantDashboard.income.week')" :is-loading="isLoading" />
    <IncomeCard :card-data="paymentStats?.month" :title="t('merchantDashboard.income.month')" :is-loading="isLoading" />
    <IncomeCard :card-data="paymentStats?.year" :title="t('merchantDashboard.income.year')" :is-loading="isLoading" />
    <IncomeCard :card-data="paymentStats?.total" :title="t('merchantDashboard.income.total')" :is-loading="isLoading" />

    <MonthlyStatement />
  </div>
</template>

<script>
import IncomeCard from './IncomeCard.vue';
import MonthlyStatement from './MonthlyStatement.vue';

export default {
  name: 'IncomeCards',
  components: {
    IncomeCard,
    MonthlyStatement,
  },
  props: {
    paymentStats: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.income-cards {
  display: flex;
  gap: 80px;
  row-gap: 0;
  flex-wrap: wrap;

  & > :last-child {
    align-self: flex-start;
    margin-top: 46px;
    margin-left: auto;
  }
}
</style>
